:root {
  /* violet */
  --primary-color: 111, 76, 255;

  /* white */
  --text-color: 256, 256, 256;
}

.textGlow {
  text-shadow: 0 0 80px rgb(192 219 255 / 75%), 0 0 32px rgb(65 120 255 / 24%);
}

.smallButton {
  width: 90px;
  min-width: 90px;
}

.xsmallButton {
  width: 70px;
  min-width: 70px;
}

.mediumButton {
  width: 180px;
}

.largeButton {
  width: 260px;
}

.thinButton {
  padding: 6px 4px 6px 4px !important;
}

.ultraThinButton {
  padding: 3px 2px 3px 2px !important;
}

.fullButton {
  width: 100%;
  font-size: 17px !important;
}

.dot {
  height: 8px;
  width: 8px;
  background-color: #5cc5e0;
  border-radius: 50%;
}

.buttonLink {
  font-family: sans-serif;
  font-size: 18px;
  padding: 12px 32px;
  cursor: pointer;
  border-radius: 4px;
  transition: all 0.3s ease;
  border-radius: 50px;
}

.button {
  font-family: sans-serif;
  font-size: 14px;
  font-weight: 700;
  padding: 12px 12px;
  margin: 1px;
  cursor: pointer;
  border-radius: 4px;
  transition: all 0.3s ease;
  border-radius: 10px;
}

.button:hover {
  transition: all 0.3s ease;
}

.buttonGlow:hover {
  box-shadow: rgba(111 76 255 / 0.5) 0px 0px 20px 0px;
}

.buttonGradientBorder {
  color: rgba(256 256 256 / 1);
  border: 2px double transparent;
  background-image: linear-gradient(rgb(13, 14, 33), rgb(13, 14, 33)),
    radial-gradient(circle at left top, rgb(1, 110, 218), rgb(217, 0, 192));
  background-origin: border-box;
  background-clip: padding-box, border-box;
}

.buttonGradient {
  background-image: linear-gradient(to right, rgb(1 134 218), rgb(182 49 167));
  border: 0;
  color: rgba(var(--text-color));
}

.buttonGradientBlue {
  background-image: linear-gradient(to right, #4591a8, #3e88f2) !important;
  border: 0;
  color: rgba(var(--text-color));
}

.buttonGradientBorderBlue {
  color: rgba(256 256 256 / 1);
  border: 1px double transparent;
  background-image: linear-gradient(#1d303a, #1d303a),
    radial-gradient(circle at left top, #4591a8, #3e88f2);
  background-origin: border-box;
  background-clip: padding-box, border-box;
}

.buttonGradientBorderBlueBlack {
  color: rgba(256 256 256 / 1);
  border: 1px double transparent;
  background-image: linear-gradient(#111, #111),
    radial-gradient(circle at left top, #4591a8, #3e88f2);
  background-origin: border-box;
  background-clip: padding-box, border-box;
}

.cardItem {
  border: 1px double transparent;
  background-image: linear-gradient(to bottom, #000000, #1d303a),
    radial-gradient(circle at left top, #51a4a1, #325a8c);
  background-origin: border-box;
  background-clip: padding-box, border-box;
  min-width: 340px;
  padding: 5px 10px 10px 10px;
}

.modal {
  background-image: linear-gradient(to bottom, #000000, #1d303a),
    radial-gradient(circle at left top, #51a4a1, #325a8c);
  background-origin: border-box;
  background-clip: padding-box, border-box;
  border-radius: "3xl";
}

.cardPropertyTitle {
  color: rgba(255, 255, 255, 0.8);
  text-align: left;
  font-weight: medium;
  line-height: 12px;
  font-size: 13px !important;
}

.cardPropertyDetail {
  color: white;
  line-height: 12px !important;
  text-align: left;
  font-weight: bold;
  font-size: 16px;
  padding-bottom: 2px;
  margin-left: 2px !important;
  margin-right: 0px;
}

.cardUSDCIcon {
  margin-left: 2px !important;
  width: 14px;
  padding-bottom: 2px;
}

.option {
  background-color: black !important;
}

.navItem {
  outline: none !important;
  font-size: 16px !important;
  line-height: 20px !important;
  cursor: pointer !important;
  display: inline-block !important;
  font-weight: 400 !important;
  padding: 0px !important;
  pointer-events: auto !important;
  position: relative !important;
  text-align: center !important;
  z-index: 0 !important;
  color: #ffffff !important;
}

.navItem::before {
  background-color: currentcolor !important;
  border-radius: 1px !important;
  bottom: 0px !important;
  content: "" !important;
  height: 2px !important;
  left: 50% !important;
  margin-left: -9px !important;
  position: absolute !important;
  -webkit-transition: 0.2s -webkit-transform cubic-bezier(0, 0, 0.1, 1),
    0.2s transform cubic-bezier(0, 0, 0.1, 1) !important;
  -moz-transition: 0.2s transform cubic-bezier(0, 0, 0.1, 1) !important;
  transition: 0.2s -ms-transform cubic-bezier(0, 0, 0.1, 1),
    0.2s -webkit-transform cubic-bezier(0, 0, 0.1, 1),
    0.2s transform cubic-bezier(0, 0, 0.1, 1) !important;
  width: 18px !important;
  -webkit-transform: scaleX(1) !important;
  -ms-transform: scaleX(1) !important;
  transform: scaleX(1) !important;
}
